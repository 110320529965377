import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormNumber = lazy(
  () => import("./form-number-BNL3MCgt.js").then((module) => ({
    default: module.FormNumber
  }))
);
function FormNumber({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormNumber, { ...props });
}
const useFormNumberContent = () => {
  return useMemo(
    () => ({
      formNumber: {
        component: FormNumber
      }
    }),
    []
  );
};
export {
  LazyFormNumber as FormNumber,
  useFormNumberContent
};
